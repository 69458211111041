/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';

import {css, jsx} from '@emotion/react';
import {getImage} from 'gatsby-plugin-image';
import {BgImage} from 'gbimage-bridge';

import {hexToRgba} from '../../helpers/utils';
import {breakpoints, colors, fonts} from '../../styles/theme';
import {Link} from './Typography';


// see: https://tobiasahlin.com/blog/how-to-animate-box-shadow/
const boxShadowPerformantCss = css`
    position: relative;

    ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    :hover,
    :focus-within {
        &::after {
          opacity: 1;
        }
    }
`;


// TODO: reformat Post base card witch common styles from Learn and Newsroom pages:
//  This component has many things in common with BaseCard from Learn page.
//  These components and styles should be reformatted to avoid duplicated code.

/*
 * The base for a Card component with a black&white image background that is a link.
 *
 * Uses of this component must specify the dimensions of the card (height/max-width/...) on its styles.
 */
const CardBase = ({image, link, children, ...props}) => {
    // Semi-transparent color gradient on top of the background image.
    // This helps the bottom text be (more) readable.
    const backgroundImageWithColorOverlay = [
        getImage(image),
        'linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 10%, rgba(255, 255, 255, 0) 40%)',
        `linear-gradient(to top, ${colors.darkPurple} 5%, ${hexToRgba(colors.darkPurple, 0)} 65%)`,
    ].reverse();

    // Add a filter to background image to be black&white by default and colored on hover/focus
    const cardBackgroundImageFilteredCss = css`
        &:before,
        &:after {
            border-radius: 5px;
            overflow: hidden;
            filter: grayscale(100%);
        }
        &:hover,
        &:focus-within {
            &:before,
            &:after {
                filter: none;
            }
        }
        &:focus-within {
            &:before,
            &:after {
                outline: 3px solid ${colors.darkPurple};
            }
        }
    `;

    // Wrap all card content in a link, styled as an interactive block
    const linkCardWrapperCss = css`
        height: 100%;
        width: 100%;
        border-radius: 5px;

        cursor: pointer;
        color: ${colors.white};
        text-decoration: none;

        &:visited {
            color: ${colors.white};
        }

        &:hover {
            color: ${colors.white};
            text-decoration: none;
        }
    `;

    const cardContentCss = css`
        padding: 1rem;

        display: flex;
        flex-direction: column;

        font-family: ${fonts.sansSerif};
        color: ${colors.white};

        @media (min-width: ${breakpoints.md}) {
            padding: 2rem;
        }
    `;

    return (
        <BgImage
            image={backgroundImageWithColorOverlay}
            css={cardBackgroundImageFilteredCss}
            className="card"
            {...props}
        >
            <Link
                to={link}
                css={[linkCardWrapperCss, cardContentCss]}
                className="card__content"
            >
                {children}
            </Link>
        </BgImage>
    );
};

CardBase.propTypes = {
    image: PropTypes.object.isRequired,
    link: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export {
    CardBase,
    boxShadowPerformantCss,
};
